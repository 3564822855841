<template>
    <v-dialog v-model="dialog" persistent max-width="550" max-height="300">
        <v-card >
            <!-- <div class="poppins f18 fw1200 d-flex justify-end px-2 py-3">
                <v-btn icon small @click="$emit('close')">
                <v-icon size="18">mdi-close</v-icon>
                </v-btn>
            </div> -->
            <div class="poppins f18 fw1200 d-flex px-5 pt-5 pb-2">

                <div class="d-flex align-center ml-2 mr-auto">
                    <span class="poppins f14 fw500 text-center"> {{ type=='preview' ? 'Preview User :' : 'Update User :'  }} <span class="text-capitalize primary--text">{{ item.first_name ? item.first_name : 'User' }} {{ item.last_name ? item.last_name : 'Account' }} {{ item.suffix ? item.suffix : '' }}</span></span>
                </div>

                <!-- <div class="d-flex align-center ml-3 mr-auto" v-if="type=='preview'">
                    <span class="secondary--text poppins f14 fw500 text-center">Preview User : <span class="text-capitalize secondary-2--text">{{ item.first_name ? item.first_name : 'User' }} {{ item.last_name ? item.last_name : 'Account' }}</span></span>
                </div>
                <div class="d-flex align-center ml-3 mr-auto" v-if="type=='update'">
                    <span class="secondary--text poppins f14 fw500 text-center">Update User : <span class="text-capitalize secondary-2--text">{{ item.first_name ? item.first_name : 'User' }} {{ item.last_name ? item.last_name : 'Account' }}</span></span>
                </div> -->
                
                <v-btn class="ml-auto" icon small @click="onEdit = false, $emit('close')">
                    <v-icon size="18">mdi-close</v-icon>
                </v-btn>
            </div>
            <v-form ref="form">
                <v-card-text>
                    <div class="mx-3 mb-5">
                        <v-row class="mb-5">
                            <v-col cols="12">
                                <label class="caption">FIRST NAME</label>
                                <v-text-field v-model="item.first_name" outlined dense hide-details="auto"  
                                class="general-custom-field roboto f14 secondary-1--text fw500" 
                                :readonly="type=='preview'"
                                :rules="[() => !!item.first_name || 'This field is required']"
                                @keyup="item.first_name = item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1)"/>
                            </v-col>
                            <v-col cols="12">
                                <label class="caption">MIDDLE NAME</label>
                                <v-text-field v-model="item.middle_name" outlined dense hide-details="auto"  
                                class="general-custom-field roboto f14 secondary-1--text fw500" 
                                :readonly="type=='preview'"
                                @keyup="item.middle_name = item.middle_name.charAt(0).toUpperCase() + item.middle_name.slice(1)"/>
                            </v-col>
                            <v-col cols="12">
                                <label class="caption">LAST NAME</label>
                                <v-text-field v-model="item.last_name" outlined dense hide-details="auto"
                                class="general-custom-field roboto f14 secondary-1--text fw500"
                                :readonly="type=='preview'"
                                :rules="[() => !!item.last_name || 'This field is required']"
                                @keyup="item.last_name = item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1)"/>
                            </v-col>
                            <v-col cols="12">
                                <label class="caption">SUFFIX</label>
                                <v-text-field v-model="item.suffix" outlined dense hide-details="auto"  
                                class="general-custom-field roboto f14 secondary-1--text fw500"
                                :readonly="type=='preview'"
                                @keyup="item.suffix = item.suffix.charAt(0).toUpperCase() + item.suffix.slice(1)"/>
                            </v-col>
                            <v-col cols="12">
                                <label class="caption">USER TYPE</label>
                                <v-select v-model="item.role" :items="items" outlined dense hide-details="auto" 
                                :readonly="type=='preview'"
                                class="general-custom-field roboto f14 secondary-1--text fw500 text-capitalize"/>
                            </v-col>
                                <v-col cols="12">
                                <label class="caption">EMAIL ADDRESS</label>
                                <v-text-field v-model="item.email" outlined dense hide-details="auto" type="email"
                                :rules="[() => 
                                  !!item.email || 'This field is required',
                                  v => /.+@.+\..+/.test(v) || 'E-mail must be valid',]"
                                class="general-custom-field roboto f14 secondary-1--text fw500" readonly/>
                            </v-col>
                        </v-row>
                        <section v-for="(tbl,  j) in registration_tables">
                            <section v-if="item.data && item.data.hasOwnProperty(tbl.title)">
                                <v-divider class="my-5"></v-divider>
                                <div class="poppins f14 fw600 secondary-2--text">
                                    {{ tbl.title }}
                                </div>
                                <div v-for="(column, i) in tbl.custom_registration_fields" :key="i" class="my-3 d-flex flex-column">
                                    <label class="caption text-uppercase"> {{column.input_field_label}} <span v-if="column.is_required">*</span></label>
                                    <input
                                        v-if="column.input_field_type === 'file'"
                                        :type="column.input_field_type"
                                        dense
                                        :readonly="type=='preview'"
                                        class="roboto f14 fw500 secondary-2--text"
                                        hide-details="auto"
                                    />
                                    <v-select
                                        v-else-if="column.input_field_type === 'dropdown' && item.data"
                                        :items="column.data"
                                        outlined
                                        :readonly="type=='preview'"
                                        dense
                                        :multiple="column.is_multiple"
                                        class="general-custom-field roboto f14 fw500 secondary-2--text"
                                        v-model="item.data[tbl.title][column.input_field_name]"
                                        hide-details="auto"
                                        :rules="column.is_required && [v => !!v || 'This is required']"
                                    >
                                    </v-select>
                                    <v-select
                                        v-else-if="column.input_field_type === 'dropdown' && !item.data"
                                        :items="column.data"
                                        outlined
                                        :readonly="type=='preview'"
                                        dense
                                        :multiple="column.is_multiple"
                                        class="general-custom-field roboto f14 fw500 secondary-2--text"
                                        hide-details="auto"
                                        :rules="column.is_required && [v => !!v || 'This is required']"
                                    >
                                    </v-select>
                                    <v-text-field 
                                        v-else-if="item.data"
                                        :type="column.input_field_type"
                                        outlined
                                        dense
                                        :readonly="column.input_field_type !== 'date' && type=='preview'"
                                        class="general-custom-field roboto f14 fw500 secondary-2--text"
                                        v-model="item.data[tbl.title][column.input_field_name]"
                                        hide-details="auto"
                                        :rules="column.is_required && [v => !!v || 'This is required']"
                                    />
                                    <v-text-field 
                                        v-else-if="!item.data"
                                        :type="column.input_field_type"
                                        outlined
                                        dense
                                        :readonly="column.input_field_type !== 'date' && type=='preview'"
                                        class="general-custom-field roboto f14 fw500 secondary-2--text"
                                        hide-details="auto"
                                        :rules="column.is_required && [v => !!v || 'This is required']"
                                        v-model="item.data[tbl.title][column.input_field_name]"
                                    />
                                    </div>
                            </section>
                            <section v-else>
                                <v-divider class="my-5"></v-divider>
                                <div class="poppins f14 fw600 secondary-2--text">
                                    {{ tbl.title }}
                                </div>
                                <div v-for="(column, i) in tbl.custom_registration_fields" :key="i" class="my-3 d-flex flex-column">
                                    <label class="caption text-uppercase"> {{column.input_field_label}} <span v-if="column.is_required">*</span></label>
                                    <input
                                        v-if="column.input_field_type === 'file'"
                                        :type="column.input_field_type"
                                        dense
                                        :readonly="type=='preview'"
                                        class="roboto f14 fw500 secondary-2--text"
                                        hide-details="auto"
                                    />
                                    <v-select
                                        v-else-if="column.input_field_type === 'dropdown'"
                                        :items="column.data"
                                        outlined
                                        :readonly="type=='preview'"
                                        dense
                                        :multiple="column.is_multiple"
                                        class="general-custom-field roboto f14 fw500 secondary-2--text"
                                        hide-details="auto"
                                        :rules="column.is_required && [v => !!v || 'This is required']"
                                    >
                                    </v-select>
                                    <v-text-field 
                                        v-else
                                        :type="column.input_field_type"
                                        outlined
                                        dense
                                        :readonly="column.input_field_type !== 'date' && type=='preview'"
                                        class="general-custom-field roboto f14 fw500 secondary-2--text"
                                        hide-details="auto"
                                        :rules="column.is_required && [v => !!v || 'This is required']"
                                        v-on:input="updateData(tbl.title, column.input_field_name, $event)"
                                    />
                                    </div>
                            </section>
                        </section>
                    </div>
                    <v-card-action v-if="type=='update'" class=" d-flex align-end">
                        <v-btn color="primary" class="text-capitalize f12 fw1200" :loading="loading" @click="updateInfo">
                            UPDATE
                        </v-btn>
                    </v-card-action>
                </v-card-text>
            </v-form>
        </v-card>
    </v-dialog>
  </template>
  

<style>
.cursor-pointer {
cursor: pointer;
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
export default {
props: ['dialog', 'item', 'type'],
    data: () => ({
        items: ['USER', 'INSTRUCTOR'],
        form : {
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',
            email: '',
            class_category_id: '',
            role: '',
        },
        loading: false,
        data: {}
    }),
    mounted() {
        this.form.first_name = this.item.first_name
        this.form.middle_name = this.item.middle_name
        this.form.last_name = this.item.last_name
        this.form.suffix = this.item.suffix
        this.form.email = this.item.email
        this.form.role = this.item.role
        this.form.class_category_id = this.item.class_category_id
    },
    computed: {
        ...mapState('admin', {
            //courses: (state) => state.courses,
            registration_tables: (state) => state.registration_tables
        }),
        ...mapState({
            tenant: (state) => state.tenant
        })
    },
    watch:{
        dialog(val) {
            if(!val) {
                this.item.first_name = this.form.first_name
                this.item.middle_name = this.form.middle_name
                this.item.last_name = this.form.last_name
                this.item.suffix = this.form.suffix
                this.item.email = this.form.email
                this.item.role = this.form.role
                this.item.class_category_id = this.form.class_category_id
            } else {
                this.form.first_name = this.item.first_name
                this.form.middle_name = this.item.middle_name
                this.form.last_name = this.item.last_name
                this.form.suffix = this.item.suffix
                this.form.email = this.item.email
                this.form.role = this.item.role
                this.form.class_category_id = this.item.class_category_id
            }
        }
    },
    methods: {
        ...mapActions('admin', [
            'updateUserDetails'
        ]),
        updateData(title, column, value){
            if(this.data.hasOwnProperty('title')) {
                this.data = this.data[title][column] = value
            } else {
                this.data = {
                    [title] : {
                        [column]: value
                    }
                }
            }

            this.item.data = { ...this.item.data, ...this.data}
        },
        updateInfo(){
            if(this.$refs.form.validate()) {
                this.updateUserDetails(this.item).then(()=>{
                    this.$store.commit('alertMutation', {
                        show: true,
                        text: `User Details Successfully Updated`,
                        type: "success"
                    })
                    this.$emit('close')
                })
            }
        }
    }
}
</script>