<template>
  <section>
    <section class="d-flex flex-wrap align-center justify-space-between">
      <SearchBar :placeholder="'Search user'" :value.sync="search" @search="$emit('search', search)"/>
      <!-- <v-text-field
        solo
        dense
        flat
        class="col-xl-3 col-lg-4 custom-border mb-5 general-custom-field f14 poppins fw500"
        hide-details
        prepend-inner-icon="mdi-magnify"
        placeholder="Search user"
        v-model="search"
      /> -->
      <v-menu offset-y transition="slide-y-transition" bottom duration :close-on-content-click="false" class="">
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            outlined
            class="text-capitalize poppins fw600 f14 small mb-5 ml-auto"
            v-bind="attrs"
            v-on="on"
            color="primary"
          >
            Filter
            <v-icon color="primary" right>
              mdi-tune
            </v-icon>
          </v-btn>
        </template>
        <v-card width="300">
          <v-card-text>
            <FormLabel :label="'ACCOUNT TYPE'"/>
            <v-chip-group v-model="filter.role" active-class="primary">
              <v-chip filter outlined label color="secondary-1" value="user" class="caption" >User</v-chip>
              <v-chip filter outlined label color="secondary-1" value="instructor" class="caption">Instructor</v-chip>
            </v-chip-group>
            <!-- <FormLabel :label="'COURSES'"/>
            <v-select 
              outlined 
              dense 
              class="general-custom-field caption secondary-1--text"
              :items="courses"
              v-model="filter.course_id"
              item-text="title"
              item-value="id"
              hide-details="auto"
              multiple
            >
            </v-select> -->
            <FormLabel :label="'STATUS'"/>
            <v-chip-group v-model="filter.status">
              <v-chip filter outlined label color="secondary-1" value="active" class="caption">Active</v-chip>
              <v-chip filter outlined label color="secondary-1" value="inactive" class="caption">Inactive</v-chip>
            </v-chip-group>
            <FormLabel :label="'DATE ADDED'"/>
            <v-chip-group column v-model="filter.created_at">
              <v-chip filter outlined label color="secondary-1" value="today" class="caption">Today</v-chip>
              <v-chip filter outlined label color="secondary-1" value="this_week" class="caption">This Week</v-chip>
              <v-chip filter outlined label color="secondary-1" value="this_month" class="caption">This Month</v-chip>
              <v-chip filter outlined label color="secondary-1" value="this_year" class="caption">This Year</v-chip>
            </v-chip-group>
          </v-card-text> 
          <v-divider/>
          <v-card-actions class="d-flex justify-space-between align-center">
            <v-btn text class="text-capitalize poppins" color="secondary" @click="$emit('resetFilters')">Clear Filters</v-btn>
            <v-btn width="120" color="primary" class="text-capitalize poppins" @click="setQuery()">Filter</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </section>
    <section v-if="totalCount !== 0" class="text-right">
      <FormLabel :label="`${totalCount} result/s`"/>
    </section>
    <v-data-table
      :headers="headers"
      :items="users"
      :loading="loading"
      class="elevation-1 poppins f10"
      hide-default-footer
    >
      <template v-slot:header.name="{ header }" class="f12 poppins fw600 text-left text-uppercase secondary-2--text">
        {{ header.text }}
      </template>
      <!-- <template v-slot:item.name="{ item }">
        {{item.first_name+' '+(item.middle_name ? getMiddleInitials(item.middle_name) : '') + ' ' + item.last_name+' ' + (item.suffix ? item.suffix : '')}}
      </template> -->
      <template v-slot:item.role="{ item }" class="text-capitalize">
        {{item.role}}
      </template><template v-slot:item.status="{ item }" class="text-capitalize">
        <v-switch
          v-model="item.status"
          true-value="active"
          false-value="inactive"
          :label="item.status"
          class="text-capitalize"
          @change="updateStatus(item.id, $event)"
        ></v-switch>
      </template>
      <template v-slot:item.created_at="{ item }" class="text-capitalize">
        {{$dateFormat.mmDDyy(item.created_at)}}
      </template>
      <template v-slot:item.action="{ item }" class="text-capitalize">
        <section class="d-flex flex-row">
          <v-btn @click="preview(item.id)" icon class="text-capitalize primary--text f12 fw600">
            <v-icon size="16" >mdi-eye-outline</v-icon>
          </v-btn>
          <!-- <v-btn @click="update(item.id)" icon class="text-capitalize primary--text f12 fw600">
            <v-icon size="16" >mdi-pencil-outline</v-icon>
          </v-btn> -->
          <v-btn v-if="item.role === 'USER'" @click="updateEnrollment(item.id)" icon class="text-capitalize primary--text f12 fw600">
            <v-icon size="16" >mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn @click="del(item.id)" icon class="text-capitalize primary--text f12 fw600">
            <v-icon size="16">mdi-trash-can-outline</v-icon>
          </v-btn>
        </section>
      </template>
    </v-data-table>
    <FormPagination 
      :pageCount="pageCount" 
      :page="page"
      :paginate="paginate"
      @page="(e) => $emit('page', e)" 
      @paginate="(e) => $emit('paginate', e)"/>
    <!-- <v-row align="center" class="my-2" v-if="users.length > 0">
      <v-col lg="1" class="hidden-md-and-down">
        <v-text-field
          :value="itemsPerPage"
          label="Items"
          type="number"
          min="-1"
          max="15"
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>
      </v-col>

      <v-col lg="10" md="12">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row> -->
    <delete-toast 
      :dialog="dialog"
      type="User"
      @cancel="dialog=false"
      @delete="remove"
    />

    <UserModal v-if="previewDialog" :dialog="previewDialog" :type="action" :item="users.filter(item => item.id == this.id)[0]" @close="previewDialog = false"/>
    <EnrollmentModal v-if="enrollmentDialog" :dialog="enrollmentDialog" :item="users.filter(item => item.id == this.id)[0]" @close="enrollmentDialog = false"/>
  </section>

</template>

<script>
import UserModal from './UserModal.vue';
import EnrollmentModal from './EnrollmentModal.vue'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    users: {
      type: Array,
      default: []
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageCount: {
      type: Number,
      default: 1
    },
    page: {
      type: Number,
      default: 1
    },
    paginate: {
      type: String,
      default: '10'
    },
    totalCount: {
      type: Number,
      default: 0
    },
    totalCount: {
      type: Number,
      default: 0
    },
    filter: {
      type: Object,
      default: {
        role: null,
        class_category_id: [],
        course_id: [],
        status: null,
        created_at: null
      }
    }
  },
  components:{
    UserModal,
    EnrollmentModal
  },
  data: () => ({
    headers: [
      { text: 'USER TYPE', value: 'role', width: "15%" },
      { text: 'NAME', value: 'name'},
      { text: 'EMAIL', value: 'email' },
      { text: 'STATUS', value: 'status', width: "15%" },
      { text: 'DATE ADDED', value: 'created_at', width: "10%", align: 'center' },
      { text: 'ACTION', value: 'action', width: "10%", sortable: false, align: 'center' },
    ],
    dialog: false,
    id: null,
    previewDialog: false,
    enrollmentDialog: false,
    action: 'preview',
    search: ''
  }),
  mounted() {
    this.getCoursesAction()
  },
  methods: {
    ...mapActions('admin', [
      'getCoursesAction'
    ]),

    del(id) {
      this.id = id
      this.dialog=true
    },

    async remove() {
      await this.$api.delete(`/admin/user-management/${this.id}`)
      .then(_ => {
        this.dialog=false
        this.$store.commit('alertMutation', {
          show: true,
          text: `User Successfully Deleted`,
          type: "success"
        })
        this.$emit('getUsers')
      }).catch(() => {
        this.$store.commit('alertMutation', {
          show: true,
          text: 'Something went wrong',
          type: "error"
        })
      })
    },
    async updateStatus(id, e) {
      await this.$api.put(`/admin/user-management/update/status`, {
        user_id: id,
        status: e
      }).then(_ => {
        this.$store.commit('alertMutation', {
          show: true,
          text: `User Status Successfully Updated`,
          type: "success"
        })
        this.$emit('getUsers')
      }).catch(() => {
        this.$store.commit('alertMutation', {
          show: true,
          text: 'Something went wrong',
          type: "error"
        })
      })
    },
    preview(e) {
      this.action='preview'
      this.id = e
      this.previewDialog = true
    },
    update(e) {
      this.action='update'
      this.id = e
      this.previewDialog = true
    },
    updateEnrollment(e) {
      this.id = e
      this.enrollmentDialog = true
    },
    setQuery() {
      let _query  = {}
      if(this.filter.role) {
        _query = { ..._query , role: this.filter.role}
      }
      if(this.filter.status) {
        _query = { ..._query , status: this.filter.status}
      }
      if(this.filter.created_at) {
        _query = { ..._query , created_at: this.filter.created_at}
      }

      if(Object.values(_query).length > 0){
        this.$router.replace({ query: { ...this.$route.query, ..._query, timestamp: Date.now() } });
      } else this.$router.replace({ query: { page: this.$route.query.page, paginate: this.$route.query.paginate, timestamp: Date.now() } })

      this.$emit('getUsers')
    }
  },
  computed: {
    ...mapState('admin', {
      courses: (state) => state.courses,
    })
  },
}
</script>